import type { Breakpoints } from "@biom3/design-tokens";

import { useWindowSizeStore } from "@/providers/BiomeWindowSize";

import { useTheme } from "./useTheme";

export function useResponsiveConditional(breakpoint: keyof Breakpoints) {
  const { state: windowWidth } = useWindowSizeStore((store) => store.width);
  const {
    base: { breakpoint: themeBreakpoints },
  } = useTheme();
  return windowWidth !== null && windowWidth >= themeBreakpoints[breakpoint];
}
