import { Layout } from "@/components";
import SEO from "@/components/core/Seo";
import { MainContent } from "@/components/layout/MainContent";
import { Dashboard } from "@components/Dashboard/Dashboard";
import { ConnectionProvider } from "@hooks/connection";
import type { GetServerSideProps } from "next";
import type { ReactElement } from "react";

function Home() {
  return (
    <ConnectionProvider>
      <Dashboard />
    </ConnectionProvider>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout screen="Quests" userJourney="Gems">
      <SEO
        description="Earn gems daily, complete quests, and unlock exclusive rewards. Collect more, achieve more – your ultimate adventure starts here!"
        title="Complete Quests & Claim Rewards | Immutable Play"
        url="https://play.immutable.com/"
      />
      <MainContent>{page}</MainContent>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
  res.setHeader("Cache-Control", "public, max-age=0, must-revalidate");

  return {
    props: {},
  };
};

export default Home;
