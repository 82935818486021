import { appConfig } from "@/constants";
import { base } from "@biom3/design-tokens";
import {
  Box,
  CloudImage,
  mediaQuerySizesHelper,
  useIntersectionObserver,
} from "@biom3/react";
import { motion } from "motion/react";

const INITIAL_X_OFFSET = -200;

export function ParallaxHero() {
  const sizes = { default: "150px", medium: "308px" };
  const top = { default: "-124px", medium: "-154px" };
  const relativeImageSizeInLayout = mediaQuerySizesHelper([
    {
      rule: "default",
      width: sizes.default,
    },
    {
      rule: "minWidth",
      breakpoint: "medium",
      width: sizes.medium,
    },
  ]);
  const { isIntersecting, domRef: setIORef } = useIntersectionObserver({
    threshold: 0.5,
  });

  return (
    <Box
      testId="heroImageOverlay"
      domRef={(el) => {
        if (!el) return;
        setIORef(el);
      }}
      sx={{
        userSelect: "none",
        pointerEvents: "none",
        zIndex: 2,
        pos: "absolute",
        overflow: "hidden",
        h: sizes,
        maxw: "100%",
        aspectRatio: "1",
        top: top,
        d: "block",
        left: "-28px",
      }}
    >
      <Box
        sx={{
          w: "100%",
          h: "100%",
          pos: "relative",
          opacity: 0,
        }}
        rc={
          <motion.div
            animate={{
              x: isIntersecting ? 0 : INITIAL_X_OFFSET,
              opacity: isIntersecting ? 1 : 0,
            }}
            initial={{ x: INITIAL_X_OFFSET, opacity: 0 }}
            transition={{
              duration: base.motion.normal.gentle.jsDuration,
              ease: base.motion.normal.gentle.jsEase,
            }}
          />
        }
      >
        <CloudImage
          imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/ravenquest-parallax.webp`}
          relativeImageSizeInLayout={relativeImageSizeInLayout}
          sx={{
            h: "100%",
            w: "100%",
            objectFit: "contain",
            top: "0px",
            pos: "absolute",
            transitionProperty: "left, right",
            transitionDuration: "base.motion.normal.gentle.cssDuration",
            transitionTimingFunction: "base.motion.normal.gentle.cssEase",
            left: "0",
            objectPosition: "right center",
          }}
        />
      </Box>
    </Box>
  );
}
