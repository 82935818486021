import { Box } from "@biom3/react";
import {
  Fit,
  Layout,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";

type MunkAnimationProps = {
  loot: boolean;
};

function MunkAnimation({ loot }: MunkAnimationProps) {
  const { RiveComponent, rive } = useRive({
    src: "/rive/munk.riv",
    autoplay: true,
    stateMachines: "Button_Munk",
    layout: new Layout({ fit: Fit.Contain }),
  });

  const lootInput = useStateMachineInput(rive, "Button_Munk", "Loot", false);

  if (lootInput) {
    lootInput.value = loot;
  }

  return <RiveComponent height={500} width={500} />;
}

type StickyMunkProps = {
  handleMunkClick: () => void;
  claimAvailable: boolean;
};

const STICKY_MUNK_SIZES = {
  INNER_BOX_WIDTH: {
    default: "200px",
    medium: "400px",
    large: "538px",
  },
  INNER_BOX_HEIGHT: {
    default: "260px",
    medium: "490px",
    large: "640px",
  },
  OUTER_BOX_WIDTH: {
    default: "125px",
    medium: "255px",
    large: "340px",
  },
  OUTER_BOX_HEIGHT: {
    default: "120px",
    medium: "225px",
    large: "310px",
  },
  OUTER_BOX_BOTTOM: {
    default: "80px",
    medium: "170px",
    large: "225px",
  },
  OUTER_BOX_RIGHT: {
    default: "47px",
    medium: "87px",
    large: "115px",
  },
};

export function StickyMunk({
  handleMunkClick,
  claimAvailable,
}: StickyMunkProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0px",
        right: "0px",
        zIndex: 9999,
        width: STICKY_MUNK_SIZES.OUTER_BOX_WIDTH,
        height: STICKY_MUNK_SIZES.OUTER_BOX_HEIGHT,
        overflow: "hidden",
        cursor: "pointer",
        animation: "slideIn 0.5s ease-in-out",
        "@keyframes slideIn": {
          "0%": {
            transform: "translateY(100%)",
            opacity: 0,
          },
          "50%": {
            // Start moving at 2 seconds (50% of 4s total duration)
            transform: "translateY(100%)",
            opacity: 0,
          },
          "100%": {
            transform: "translateY(0)",
            opacity: 1,
          },
        },
        animationDuration: "3s", // 4s total: 2s delay + 2s animation
        animationFillMode: "forwards",
      }}
      onClick={handleMunkClick}
      onTouchEnd={handleMunkClick}
    >
      <Box
        sx={{
          position: "relative",
          bottom: STICKY_MUNK_SIZES.OUTER_BOX_BOTTOM,
          right: STICKY_MUNK_SIZES.OUTER_BOX_RIGHT,
          width: STICKY_MUNK_SIZES.INNER_BOX_WIDTH,
          height: STICKY_MUNK_SIZES.INNER_BOX_HEIGHT,
        }}
      >
        <MunkAnimation loot={claimAvailable} />
      </Box>
    </Box>
  );
}
