import { NEXT_IMAGE_QUALITY, appConfig } from "@/constants";
import { usePassportProvider } from "@/context/PassportProvider";
import { RuleId } from "@/domain/gems-game/domain";
import { useAccessToken } from "@/hooks";
import { useGetWalletGems, useGetWalletLatestEarnings } from "@/hooks/api";
import { authorizedPost } from "@/utils/request";
import { trackLegacyEvent } from "@analytics";
import { base } from "@biom3/design-tokens";
import { Box, Button, HeroCard } from "@biom3/react";
import { ClaimSignInBonusModal } from "@components/ClaimSignInBonusModal/ClaimSignInBonusModal";
import Image from "next/image";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface SignedInBannerProps {
  claimedSignUpReward: boolean;
  hasLoggedInViaBanner: boolean;
  walletAddress: string | null;
}

const SignedInBanner = ({
  claimedSignUpReward,
  hasLoggedInViaBanner,
  walletAddress,
}: SignedInBannerProps) => {
  const { mutate } = useGetWalletLatestEarnings(walletAddress ?? undefined);
  const { t } = useTranslation();
  const [isClaimSignInBonusModalOpen, setIsClaimSignInBonusModalOpen] =
    useState(false);
  const hasOpenedModal = useRef(false);
  const [isClaimingReward, setIsClaimingReward] = useState(false);

  const { mutate: refetchGems } = useGetWalletGems(walletAddress, false);
  const getAccessToken = useAccessToken();

  // Shows users that have logged in via banner, and claimed the modal.
  useEffect(() => {
    if (
      hasLoggedInViaBanner &&
      claimedSignUpReward &&
      !hasOpenedModal.current
    ) {
      openModal();
    }
  }, [hasLoggedInViaBanner, claimedSignUpReward]);

  const openModal = () => {
    setIsClaimSignInBonusModalOpen(true);
    hasOpenedModal.current = true;
  };

  const claimRewardCta = () => {
    trackLegacyEvent({
      screen: "SignUpBonusBanner",
      action: "Pressed",
      control: "ClaimPrize",
      controlType: "Button",
      userJourney: "SignUp",
      extras: {
        questGems: 30,
      },
    });
    openModal();
  };

  const closeModal = () => {
    setIsClaimSignInBonusModalOpen(false);
  };

  const claimReward = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setIsClaimingReward(true);
    try {
      await authorizedPost(
        "/v1/rewards/claim",
        {
          event: "sign-up-reward-completed",
        },
        accessToken,
      );

      trackLegacyEvent({
        screen: "SignUpBonusModal",
        action: "Succeeded",
        control: "Close",
        controlType: "Button",
        userJourney: "SignUp",
        extras: {
          questGems: 30,
        },
      });
    } catch (error) {
      console.error("Failed to claim sign in bonus", error);
    } finally {
      await Promise.all([mutate(), refetchGems()]);
      setIsClaimingReward(false);
    }
  };

  return (
    <>
      {!claimedSignUpReward && !isClaimingReward ? (
        <Box
          sx={{
            marginBottom: "base.spacing.x6",
          }}
        >
          <HeroCard
            sx={{
              background: "base.color.translucent.inverse.800",
              height: ["256px", "270px", "245px", "216px"],
            }}
            aspectRatio="unset"
          >
            <HeroCard.AssetImage
              loading="eager"
              use={
                <Image
                  src={`${appConfig.ASSET_BASE_URL}/images/rewards/sign-up-reward-banner.webp`}
                  alt="Sign up reward banner"
                  fill
                  priority
                  unoptimized
                  quality={NEXT_IMAGE_QUALITY}
                  style={{ objectFit: "contain", objectPosition: "right" }}
                />
              }
            />

            <HeroCard.Title size={["small", "medium", "medium"]}>
              {t("welcome_reward_banner_unclaimed_prize_title")} <br />
            </HeroCard.Title>
            <Box
              sx={{
                mb: base.spacing.x6,
              }}
            >
              <HeroCard.Description size={"small"}>
                {t("welcome_reward_banner_claim_prize_description")}
              </HeroCard.Description>
            </Box>
            {walletAddress && (
              <HeroCard.Button size="small" onClick={claimRewardCta}>
                {t("claim_reward")}
              </HeroCard.Button>
            )}
            {!walletAddress && (
              <HeroCard.Button size="small" disabled>
                <Box>Connecting wallet</Box>
                <Button.Icon icon="Loading" />
              </HeroCard.Button>
            )}
          </HeroCard>
        </Box>
      ) : null}

      {isClaimSignInBonusModalOpen ? (
        <ClaimSignInBonusModal
          claimedSignUpReward={claimedSignUpReward}
          claimReward={claimReward}
          close={closeModal}
        />
      ) : null}
    </>
  );
};

interface SignedOutBannerProps {
  triggerLogin: () => void;
  isAuthenticated: boolean;
}

const SignedOutBanner = ({
  triggerLogin,
  isAuthenticated,
}: SignedOutBannerProps) => {
  const { t } = useTranslation();

  const handleSignIn = useCallback(() => {
    if (!isAuthenticated) {
      trackLegacyEvent({
        screen: "Quest",
        userJourney: "Onboarding",
        control: "JoinNowHero",
        controlType: "Button",
        action: "Pressed",
      });
      triggerLogin();
    }
  }, [isAuthenticated, triggerLogin]);

  return (
    <Box
      sx={{
        marginBottom: "base.spacing.x6",
      }}
    >
      <HeroCard
        sx={{
          background: "base.color.translucent.inverse.800",
          height: ["256px", "270px", "245px", "216px"],
        }}
        aspectRatio="unset"
      >
        <HeroCard.AssetImage
          loading="eager"
          use={
            <Image
              src={`${appConfig.ASSET_BASE_URL}/images/rewards/sign-up-reward-banner.webp`}
              alt="Sign up reward banner"
              fill
              priority
              unoptimized
              quality={NEXT_IMAGE_QUALITY}
              style={{ objectFit: "contain", objectPosition: "right" }}
            />
          }
        />
        <HeroCard.Title size={["small", "medium", "medium"]}>
          {t("welcome_reward_banner_title_line1")}
          <br />
          {t("welcome_reward_banner_title_line2")}
        </HeroCard.Title>
        <Box
          sx={{
            mb: base.spacing.x6,
          }}
        >
          <HeroCard.Description size={"small"}>
            {t("welcome_reward_banner_description")}
          </HeroCard.Description>
        </Box>
        <HeroCard.Button size="small" onClick={handleSignIn}>
          {t("welcome_reward_banner_cta")}
        </HeroCard.Button>
      </HeroCard>
    </Box>
  );
};

const QuestsPageBanner = () => {
  const { passportState, triggerLoginWithCallback, isLoggedIn, walletAddress } =
    usePassportProvider();

  const { latestEarnings } = useGetWalletLatestEarnings(walletAddress);

  const claimedSignUpReward = useMemo(
    () =>
      latestEarnings?.earnings.some(
        (earning) => earning.ruleId === RuleId.SIGNUP_REWARD,
      ) ?? false,
    [latestEarnings],
  );

  const [hasLoggedInViaBanner, setHasLoggedInViaBanner] = useState(false);

  const handleLogin = async () => {
    trackLegacyEvent({
      screen: "SignUpBonusBanner",
      action: "Pressed",
      control: "Login",
      controlType: "Button",
      userJourney: "SignUp",
    });
    triggerLoginWithCallback(() => {
      //triggered when user logs in from the banner
      setHasLoggedInViaBanner(true);
    });
  };

  if (!passportState.ready) {
    return null;
  }

  if (!latestEarnings && isLoggedIn && walletAddress) {
    return null;
  }

  if (!passportState.authenticated) {
    return (
      <SignedOutBanner
        triggerLogin={handleLogin}
        isAuthenticated={passportState.authenticated}
      />
    );
  }

  return (
    <SignedInBanner
      claimedSignUpReward={claimedSignUpReward}
      hasLoggedInViaBanner={hasLoggedInViaBanner}
      walletAddress={walletAddress || null}
    />
  );
};

export default QuestsPageBanner;
