import { SIDEBAR_WIDTH } from "@/constants";
import { Box, HeroBackground, mediaQuerySizesHelper } from "@biom3/react";

export const FeaturedQuestTakeoverBackground = ({
  backgroundImg,
}: {
  backgroundImg: string;
}) => {
  return (
    <>
      <Box
        sx={{
          pos: "absolute",
          top: "0",
          left: "0",
          w: "100%",
          h: "100%",
        }}
      />
      {backgroundImg && (
        <HeroBackground
          sx={{
            pos: "absolute",
            top: "0",
            left: "0",
            bradtl: "base.borderRadius.x8",
            bradtr: "base.borderRadius.x8",
          }}
          aspectRatio={{ default: "1", xLarge: "16/11" }}
        >
          <HeroBackground.Image
            loading="eager"
            imageUrl={backgroundImg.toString()}
            responsiveSizes={[
              256, 512, 1024, 1280, 1920, 2160, 2304, 2560, 2732, 2880, 3072,
              3200, 3456, 3840, 4096,
            ]}
            relativeImageSizeInLayout={mediaQuerySizesHelper([
              { rule: "default", width: "100dvw - 16px" },
              {
                rule: "minWidth",
                breakpoint: "medium",
                width: `calc(100dvw - ${SIDEBAR_WIDTH} - 32px)`,
              },
            ])}
          />
          <HeroBackground.ZoomText
            textScaleRatio={"1.08"}
            sx={{ opacity: 0.2, top: "-4px" }}
          >
            RAVENQUEST
          </HeroBackground.ZoomText>
        </HeroBackground>
      )}
    </>
  );
};
