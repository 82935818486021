import { pageLegacyEvent } from "@analytics";
import { useEffect, useState } from "react";

export function usePageEventOnce(props: {
  screen: string;
  userJourney: string;
  action?: string;
  extras?: Record<string, unknown>;
}) {
  const [eventAlreadySent, setEventAlreadySent] = useState(false);

  useEffect(() => {
    if (eventAlreadySent) return;
    setEventAlreadySent(true);
    pageLegacyEvent(props);
  }, [eventAlreadySent, props]);
}
