import { usePassportProvider } from "@/context";
import { usePageEventOnce } from "@/hooks/usePageEventOnce";
import { preloadAllQuestCMS, usePromoTiles } from "@hooks/cms";
import { useFlag } from "@hooks/useFlags";
import { RenderOnceInView } from "@ui-kit";
import { useFlags } from "launchdarkly-react-client-sdk";
import { lazy, useEffect, useMemo } from "react";
import { FeaturedQuestTakeoverBackground } from "../FeaturedQuestTakeoverBackground";
import { FeaturedQuests } from "../QuestTiles/FeaturedQuests/FeaturedQuests";
import { questMediaURL } from "../QuestTiles/utils";
import QuestsPageBanner from "../QuestsPageBanner/QuestsPageBanner";
import ParallaxContainer from "../Takeover/ParallaxContainer/ParallaxContainer";
import { Wrapper as RavenQuestWrapper } from "../Takeover/RavenQuest/Wrapper/Wrapper";
import { WelcomeBackBonus } from "../WelcomeBackBonus/WelcomeBackBonus";
import { LoadingModal } from "../core/LoadingModal";

const LazySeriesQuests = lazy(() =>
  import("@/components/QuestTiles/SeriesQuests/SeriesQuests").then(
    (module) => ({
      default: module.SeriesQuests,
    }),
  ),
);

const LazySideQuests = lazy(() =>
  import("@components/QuestTiles/SideQuests/SideQuests").then((module) => ({
    default: module.SideQuests,
  })),
);

const LazyMainQuests = lazy(() =>
  import("@components/QuestTiles/MainQuests/MainQuests").then((module) => ({
    default: module.MainQuests,
  })),
);

const LazyGemsLayout = lazy(() =>
  import("@components/Gems/Layout/Layout").then((module) => ({
    default: module.GemsLayout,
  })),
);

const LazyPromotedSeriesQuests = lazy(() =>
  import("@/components/QuestTiles/SeriesQuests/PromotedSeriesQuests").then(
    (module) => ({
      default: module.PromotedSeriesQuests,
    }),
  ),
);

const LazyOnboardingQuests = lazy(() =>
  import("@/components/QuestTiles/OnboardingQuests/OnboardingQuests").then(
    (module) => ({
      default: module.OnboardingQuests,
    }),
  ),
);

const LazyGameShowcase = lazy(() =>
  import("@components/GameShowcase").then((module) => ({
    default: module.GameShowcase,
  })),
);

export function Dashboard() {
  const { data: promoTiles } = usePromoTiles({
    isFetchingLatestEarningsPaused: false,
  });
  const { passportState } = usePassportProvider();

  const newAccountLoading = useMemo(
    () =>
      passportState.ready &&
      passportState.authenticated &&
      !passportState.zkEvmRegistered,
    [
      passportState.ready,
      passportState.authenticated,
      passportState.zkEvmRegistered,
    ],
  );

  usePageEventOnce({ screen: "Dashboard", userJourney: "Claim" });
  const takeoverBackgroundEnabled = useFlag("engagementFeaturedQuestThemedBg");
  const takeoverEasterEggEnabled = useFlag("engagementTakeoverEasterEgg");
  const backgroundImg = "ravenquest-takeover-bg.webp";
  const { bonusCodeRedemptionEnabled } = useFlags();

  useEffect(() => {
    preloadAllQuestCMS();
  }, []);

  return (
    <>
      {!passportState.ready ? (
        <LoadingModal loading={true} text={"Loading..."} />
      ) : newAccountLoading ? (
        <LoadingModal
          loading={true}
          text={[
            "Initializing profile...",
            "Creating wallet...",
            "Connecting to zkEVM...",
            "Loading game assets...",
            "Almost ready to play...",
            "Preparing data...",
          ]}
        />
      ) : (
        <>
          {takeoverBackgroundEnabled && (
            <FeaturedQuestTakeoverBackground
              backgroundImg={questMediaURL(backgroundImg).toString()}
            />
          )}
          {takeoverEasterEggEnabled && <RavenQuestWrapper />}
          <FeaturedQuests />
          <QuestsPageBanner />
          <RenderOnceInView placeHolderHeight="0px">
            <ParallaxContainer>
              <LazyOnboardingQuests />
            </ParallaxContainer>
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <LazyPromotedSeriesQuests />
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <LazyMainQuests />
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <ParallaxContainer>
              <LazyGemsLayout />
            </ParallaxContainer>
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <LazySeriesQuests />
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <LazySideQuests />
          </RenderOnceInView>
          <RenderOnceInView placeHolderHeight="0px">
            <LazyGameShowcase gameTiles={promoTiles ?? []} />
          </RenderOnceInView>
        </>
      )}
      {bonusCodeRedemptionEnabled ? <WelcomeBackBonus /> : null}
    </>
  );
}
