import { RainingGems } from "@/components/RainingGems/RainingGems";
import { BonusModal } from "@/components/Takeover/RavenQuest/BonusModal/BonusModal";
import { StickyMunk } from "@components/Takeover/RavenQuest/StickyMunk/StickyMunk";
import { useCallback, useEffect, useState } from "react";

import { usePassportProvider } from "@/context";
import type { RuleId } from "@/domain/gems-game/domain";
import { useAccessTokenState } from "@/hooks";
import { useGetWalletLatestEarnings } from "@/hooks/api";
import { authorizedPost } from "@/utils/request";
import {
  type AnalyticsAction,
  type AnalyticsComponent,
  trackEvent,
} from "@analytics";

const RAVEN_QUEST_MUNK_QUEST_ID =
  "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-takeover";
const GAME_ID = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b";
const GAME_NAME = "RavenQuest";
const SECTION = "RavenQuestBonusModal";
const MUNK_CLICKS_REQUIRED = 5;

function trackRQEvent({
  component = "Button",
  action,
  control,
  extras = {},
}: {
  component?: AnalyticsComponent;
  action: AnalyticsAction;
  control: string;
  extras?: Record<string, unknown>;
}) {
  trackEvent({
    component,
    action,
    extras: {
      gameId: GAME_ID,
      gameName: GAME_NAME,
      section: SECTION,
      control,
      ...extras,
    },
  });
}

export function Wrapper() {
  const [bonusModalVisible, setBonusModalVisible] = useState(false);
  const [isRainingGemsVisible, setIsRainingGemsVisible] = useState(false);
  const [munkClickCounter, setMunkClickCounter] = useState(0);
  const [isClaimingReward, setIsClaimingReward] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);

  const { walletAddress } = usePassportProvider();
  const accessToken = useAccessTokenState();
  const { latestEarnings } = useGetWalletLatestEarnings(walletAddress);
  const isMunkVisible =
    walletAddress &&
    latestEarnings &&
    !latestEarnings.totalEarningsForRule(RAVEN_QUEST_MUNK_QUEST_ID as RuleId);

  const claimAvailable =
    munkClickCounter >= MUNK_CLICKS_REQUIRED && bonusModalVisible;

  const handleClaimBonus = useCallback(async () => {
    if (!accessToken) return;
    trackRQEvent({ action: "Pressed", control: "ClaimBonus" });
    setIsClaimingReward(true);
    try {
      await authorizedPost(
        "/v1/rewards/claim",
        { event: `${RAVEN_QUEST_MUNK_QUEST_ID}-completed` },
        accessToken,
      );
      setBonusModalVisible(false);
      setIsRainingGemsVisible(true);
      trackRQEvent({
        component: "Effect",
        action: "Succeeded",
        control: "ClaimBonus",
      });

      setTimeout(() => {
        setIsRainingGemsVisible(false);
        setIsClaimed(true);
      }, 5000);
    } catch (error) {
      console.error("Failed to claim chest", error);
      trackRQEvent({
        component: "Effect",
        action: "Failed",
        control: "ClaimBonus",
      });
    } finally {
      setIsClaimingReward(false);
    }
  }, [accessToken]);

  const handleBonusModalClose = useCallback(() => {
    trackRQEvent({ action: "Pressed", control: "Close" });
    setBonusModalVisible(false);
    setMunkClickCounter(0);
  }, []);

  const handleMunkClick = useCallback(() => {
    const newCount = munkClickCounter + 1;
    setMunkClickCounter(newCount);

    trackRQEvent({
      action: "Pressed",
      control: "MunkClick",
      extras: { munkClickCounter: newCount },
    });

    if (newCount >= MUNK_CLICKS_REQUIRED) {
      trackRQEvent({
        action: "Pressed",
        control: "ShowBonusModal",
        extras: { munkClickCounter: newCount },
      });
      setBonusModalVisible(true);
    }
  }, [munkClickCounter]);

  useEffect(() => {
    trackRQEvent({ action: "Viewed", control: "MunkClick" });
  }, []);

  return (
    <>
      {!isClaimed && isMunkVisible && (
        <StickyMunk
          handleMunkClick={handleMunkClick}
          claimAvailable={claimAvailable}
        />
      )}
      <BonusModal
        visible={bonusModalVisible}
        onClose={handleBonusModalClose}
        onClaimBonus={handleClaimBonus}
        isClaimingReward={isClaimingReward}
      />
      {isRainingGemsVisible && <RainingGems />}
    </>
  );
}
