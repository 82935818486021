import { MODAL_WIDTH, appConfig } from "@/constants";
import { ConfirmationDialog } from "@biom3/react";

type BonusModalProps = {
  onClose: () => void;
  onClaimBonus: () => void;
  visible: boolean;
  isClaimingReward: boolean;
};

export const BonusModal = ({
  onClose,
  onClaimBonus,
  visible,
  isClaimingReward,
}: BonusModalProps) => {
  return (
    <ConfirmationDialog visible={visible}>
      <ConfirmationDialog.Content
        sx={{
          background: "base.color.brand.2",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${appConfig.ASSET_BASE_URL}/images/quests/ravenquest-bonus-modal-bg.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          height: "650px",
          width: {
            default: "calc(100dvw - 40px)",
            small: MODAL_WIDTH,
          },
        }}
      >
        <ConfirmationDialog.Content.Title sx={{ mt: "350px" }}>
          You got 25 Gems!
        </ConfirmationDialog.Content.Title>
        <ConfirmationDialog.Content.Image
          imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/ravenquest-bonus-modal.svg`}
          aspectRatio={1}
          responsiveSizes={[370, 450, 512, 720, 1024]}
          sx={{
            pos: "absolute",
            top: "-100px",
            left: ["-80px", "-75px", "-36px"],
            width: "450px",
            maxWidth: "450px",
            zIndex: 10,
          }}
        />
        <ConfirmationDialog.Content.Caption>
          You must have been munking around the right spot!
        </ConfirmationDialog.Content.Caption>
        <ConfirmationDialog.Content.Button
          onClick={onClaimBonus}
          disabled={isClaimingReward}
        >
          {isClaimingReward ? "Claiming..." : "Claim 25 Gems"}
        </ConfirmationDialog.Content.Button>
        <ConfirmationDialog.Content.CloseButtCon
          onClick={onClose}
          sx={{
            zIndex: 11,
            cursor: "pointer",
          }}
        />
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
};
