import { usePassportLogin } from "@/hooks/usePassportLogin";
import { LoadingModal } from "../core/LoadingModal";
import { WelcomeBackBonusModal } from "./WelcomeBackBonusModal";
import { useWelcomeBackBonus } from "./useWelcomeBackBonus";

export const WelcomeBackBonus = () => {
  const { welcomeBackModalState, setWelcomeBackModalState } =
    useWelcomeBackBonus();

  const { login, loading } = usePassportLogin();

  return (
    <>
      {welcomeBackModalState !== undefined ? (
        <WelcomeBackBonusModal
          state={welcomeBackModalState}
          onLogin={login}
          onClose={() => setWelcomeBackModalState(undefined)}
        />
      ) : null}
      <LoadingModal loading={loading} text="Signing In..." />
    </>
  );
};
