import { trackLegacyEvent } from "@analytics";
import { Body, Button, Heading } from "@biom3/react";
import { useEffect, useMemo, useState } from "react";

import { appConfig } from "@/constants";

import { RewardBaseModal } from "../RewardBaseModal/RewardBaseModal";
import { WELCOME_BACK_BONUS_CODE } from "./useWelcomeBackBonus";

const standardRotatingGem = `${appConfig.PLAY_ASSETS_CDN_BASE_URL}/videos/immutable/rotating-gem--standard.mp4`;
const standardRotatingGemWebm = `${appConfig.PLAY_ASSETS_CDN_BASE_URL}/videos/immutable/rotating-gem--standard.webm`;

export type WelcomeBackBonusModalState =
  | undefined
  | "login"
  | "success"
  | "already_claimed";

interface WelcomeBackBonusModalProps {
  state: WelcomeBackBonusModalState;
  onClose: () => void;
  onLogin: () => void;
}

const NUM_GEMS_BONUS = 100;

export const WelcomeBackBonusModal = ({
  state,
  onClose,
  onLogin,
}: WelcomeBackBonusModalProps) => {
  const [videoOpacity, setVideoOpacity] = useState(0);

  const modalContent = useMemo(() => {
    switch (state) {
      case "success":
        return {
          heading: `You earned ${NUM_GEMS_BONUS} Bonus Gems!`,
          body: "Play around and earn more gems. You'll soon be able to convert gems into real rewards!",
          buttonText: "Continue to Play",
        };
      case "already_claimed":
        return {
          heading: "Bonus Already Claimed!",
          body: "You've already claimed your bonus gems! Keep playing to earn more rewards.",
          buttonText: "Continue to Play",
        };
      default:
        return {
          heading: "Welcome Back!",
          body: "Your bonus gems are waiting. Login to claim them!",
          buttonText: "Claim Now",
        };
    }
  }, [state]);

  const screen = useMemo(() => {
    return state === "success"
      ? "WelcomeBackBonusSuccess"
      : state === "already_claimed"
        ? "WelcomeBackBonusAlreadyClaimed"
        : "WelcomeBackBonusLogin";
  }, [state]);

  // PassportDashboardClaimWelcomeBackBonusLogin_ScreenEffectViewed
  // PassportDashboardClaimWelcomeBackBonusSuccess_ScreenEffectViewed
  // PassportDashboardClaimWelcomeBackBonusAlreadyClaimed_ScreenEffectViewed
  useEffect(() => {
    trackLegacyEvent({
      screen,
      action: "Viewed",
      control: "Screen",
      controlType: "Effect",
      userJourney: "Claim",
      extras: {
        code: WELCOME_BACK_BONUS_CODE,
      },
    });
  }, [screen]);

  const close = () => {
    trackLegacyEvent({
      screen,
      action: "Closed",
      control: "Button",
      controlType: "Button",
      userJourney: "Claim",
    });
    onClose();
  };

  const handleCta = () => {
    if (state === "login") {
      // PassportDashboardClaimWelcomeBackBonusLogin_ButtonButtonPressed
      trackLegacyEvent({
        screen,
        action: "Pressed",
        control: "Button",
        controlType: "Button",
        userJourney: "Claim",
        extras: {
          code: WELCOME_BACK_BONUS_CODE,
        },
      });
      onLogin();
    }
    close();
  };

  return (
    <RewardBaseModal visible={true} close={close}>
      <video
        style={{
          alignSelf: "center",
          width: "160px",
          aspectRatio: 1,
          opacity: videoOpacity,
          transition: "opacity 1000ms ease-in-out",
        }}
        autoPlay
        preload="auto"
        loop
        muted
        controls={false}
        onLoadedData={() => setVideoOpacity(1)}
      >
        <source src={standardRotatingGem} type="video/mp4" />
        <source src={standardRotatingGemWebm} type="video/webm" />
      </video>

      <Heading
        size="medium"
        sx={{
          px: "base.spacing.x3",
          textAlign: "center",
          lineHeight: "1.2",
        }}
      >
        {modalContent.heading}
      </Heading>
      <Body
        size="small"
        sx={{
          color: "base.color.text.body.secondary",
          textAlign: "center",
        }}
      >
        {modalContent.body}
      </Body>
      <Button
        size="large"
        variant="primary"
        onClick={handleCta}
        sx={{ mt: "auto" }}
      >
        {modalContent.buttonText}
      </Button>
    </RewardBaseModal>
  );
};
