import { useFlag } from "@/hooks/useFlags";
import { Box } from "@biom3/react";
import { ParallaxHero } from "../ParallaxHero/ParallaxHero";

export default function ParallaxContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const takeoverBackgroundEnabled = useFlag("engagementFeaturedQuestThemedBg");

  if (!takeoverBackgroundEnabled) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <ParallaxHero />
      {children}
    </Box>
  );
}
