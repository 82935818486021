import { usePassportProvider } from "@/context";
import { useGetWalletLatestEarnings } from "@/hooks/api";
import { useQuests } from "@/hooks/cms";
import { QuestsSection } from "@components/TilesSection/QuestsSection";
import { QuestTileType } from "@imx-tokens-ts/gems-game/domain";

export const FeaturedQuests = () => {
  const { data, isLoading } = useQuests();
  const featuredQuests = data?.featuredQuests || [];
  const { walletAddress } = usePassportProvider();
  const { latestEarnings } = useGetWalletLatestEarnings(walletAddress);
  return (
    <QuestsSection
      tileType={QuestTileType.FeaturedQuest}
      title={""}
      filterOptions={[]} // featured quests don't have filters
      latestEarnings={latestEarnings}
      data={featuredQuests}
      autoplay={true}
      isThumbnail={true}
      isLoading={isLoading}
      showDivider={false}
    />
  );
};
