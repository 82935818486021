import { usePassportProvider } from "@/context";
import { useGetWalletGems, useGetWalletLatestEarnings } from "@/hooks/api";
import { useAccessToken } from "@/hooks/useAccessToken";
import { authorizedPost } from "@/utils/request";
import { trackLegacyEvent } from "@analytics";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import type { WelcomeBackBonusModalState } from "./WelcomeBackBonusModal";

export const WELCOME_BACK_BONUS_CODE = "WELCOMEBACK100";
const PENDING_WELCOME_BACK_BONUS_STORAGE_KEY = `pending_${WELCOME_BACK_BONUS_CODE}`;

export const useWelcomeBackBonus = () => {
  const { passportState, walletAddress } = usePassportProvider();
  const getAccessToken = useAccessToken();
  const { mutate } = useGetWalletLatestEarnings(walletAddress ?? undefined);
  const { mutate: refetchGems } = useGetWalletGems(
    walletAddress || null,
    false,
  );
  const [isClaiming, setIsClaiming] = useState(false);

  const [welcomeBackModalState, setWelcomeBackModalState] =
    useState<WelcomeBackBonusModalState>(undefined);
  const router = useRouter();

  useEffect(() => {
    const { code } = router.query;
    if (code === WELCOME_BACK_BONUS_CODE) {
      localStorage.setItem(PENDING_WELCOME_BACK_BONUS_STORAGE_KEY, "true");
    }
  }, [router.query]);

  const bonusCodePresent = useCallback(() => {
    const { code } = router.query;
    return code === WELCOME_BACK_BONUS_CODE;
  }, [router.query]);

  const claimBonus = useCallback(async () => {
    setIsClaiming(true);
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error("no_access_token");
      }

      const response = await authorizedPost(
        "/v1/rewards/claim",
        {
          event: "bonus-welcome-back-completed",
        },
        accessToken,
      );

      if (response.claim_status === "already_claimed") {
        setWelcomeBackModalState("already_claimed");
        throw new Error("already_claimed");
      }

      setWelcomeBackModalState("success");
      // PassportDashboardClaimWelcomeBackBonus_ScreenEffectSucceeded
      trackLegacyEvent({
        screen: "WelcomeBackBonus",
        action: "Succeeded",
        control: "Screen",
        controlType: "Effect",
        userJourney: "Claim",
        extras: {
          code: WELCOME_BACK_BONUS_CODE,
        },
      });

      await Promise.all([mutate(), refetchGems()]);
    } catch (error: unknown) {
      // PassportDashboardClaimWelcomeBackBonus_ScreenEffectFailed
      trackLegacyEvent({
        screen: "WelcomeBackBonus",
        action: "Failed",
        control: "Screen",
        controlType: "Effect",
        userJourney: "Claim",
        extras: {
          error: error instanceof Error ? error.message : "unknown",
        },
      });
    } finally {
      setIsClaiming(false);
      localStorage.removeItem(PENDING_WELCOME_BACK_BONUS_STORAGE_KEY);
    }
  }, [getAccessToken, mutate, refetchGems]);

  useEffect(() => {
    const pendingBonus = localStorage.getItem(
      PENDING_WELCOME_BACK_BONUS_STORAGE_KEY,
    );
    if (!pendingBonus) {
      return;
    }

    if (
      passportState.ready &&
      !passportState.authenticated &&
      bonusCodePresent()
    ) {
      setWelcomeBackModalState("login");
    }
    // passportState.zkEvmRegistered - necessary for new accounts, their accessToken will 401 the claim endpoint after first signing up without an address setup.
    else if (
      passportState.ready &&
      passportState.authenticated &&
      passportState.zkEvmRegistered &&
      !isClaiming
    ) {
      claimBonus();
    }
  }, [
    passportState.authenticated,
    passportState.ready,
    passportState.zkEvmRegistered,
    claimBonus,
    isClaiming,
    bonusCodePresent,
  ]);

  return {
    welcomeBackModalState,
    setWelcomeBackModalState,
  };
};
