import { MODAL_HEIGHT, MODAL_WIDTH, appConfig } from "@/constants";
import {
  Body,
  Button,
  CloudImage,
  Heading,
  LoadingOverlay,
  Video,
} from "@biom3/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RewardBaseModal } from "../RewardBaseModal/RewardBaseModal";

interface ClaimSignInBonusModalProps {
  claimedSignUpReward: boolean;
  close: () => void;
  claimReward: () => void;
}
export const ClaimSignInBonusModal = ({
  claimedSignUpReward,
  close,
  claimReward,
}: ClaimSignInBonusModalProps) => {
  const { t } = useTranslation();
  const [animModalVisible, setAnimModalVisible] = useState(true);
  const [finalModalVisible, setFinalModalVisible] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  useEffect(() => {
    if (claimedSignUpReward) {
      setAnimModalVisible(false);
      setFinalModalVisible(true);
      return;
    }
    setAnimModalVisible(true);
  }, [claimedSignUpReward]);

  const handleAnimationEnded = () => {
    setAnimModalVisible(false);
    setFinalModalVisible(true);
  };

  const handleClose = () => {
    if (!claimedSignUpReward) {
      claimReward();
    }
    if (close) {
      close();
    }
  };
  return (
    <>
      {/* This is to force CloudImage to preload at our required size. */}
      <CloudImage
        imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/30-gems-card.webp`}
        relativeImageSizeInLayout="160px"
        sx={{
          width: "160px",
          position: "absolute",
          opacity: 0,
        }}
      />

      <RewardBaseModal visible={animModalVisible} withPadding={false}>
        <Video
          sx={{
            objectFit: "contain",
            width: MODAL_WIDTH,
            height: MODAL_HEIGHT,
          }}
          mimeType="video/mp4"
          videoUrl={`${appConfig.PLAY_ASSETS_CDN_BASE_URL}/videos/immutable/claimBonusChestModal.mp4`}
          onLoadedData={() => setVideoLoading(false)}
          onError={() => setVideoLoading(false)}
          autoPlay
          muted={false}
          onEnded={() => {
            handleAnimationEnded();
          }}
        />
        <LoadingOverlay visible={videoLoading}>
          <LoadingOverlay.Content>
            <LoadingOverlay.Content.LoopingText
              text={["Preparing your reward..."]}
            />
          </LoadingOverlay.Content>
        </LoadingOverlay>
      </RewardBaseModal>
      <RewardBaseModal
        visible={finalModalVisible && !claimedSignUpReward}
        close={handleClose}
      >
        <CloudImage
          imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/30-gems-card.webp`}
          relativeImageSizeInLayout="160px"
          sx={{
            alignSelf: "center",
            width: "160px",
          }}
        />
        <Heading
          size="medium"
          sx={{
            px: "base.spacing.x3",
            textAlign: "center",
          }}
        >
          {t("welcome_reward_modal_heading")}
        </Heading>
        <Body
          size="small"
          sx={{
            color: "base.color.text.body.secondary",
            textAlign: "center",
          }}
        >
          {t("welcome_reward_modal_body")}
        </Body>
        <Button
          size="large"
          variant="primary"
          onClick={handleClose}
          sx={{ mt: "auto" }}
        >
          {t("welcome_reward_modal_cta")}
        </Button>
      </RewardBaseModal>
      <RewardBaseModal
        visible={finalModalVisible && claimedSignUpReward}
        close={handleClose}
      >
        <CloudImage
          imageUrl={`${appConfig.ASSET_BASE_URL}/images/quests/30-gems-card.webp`}
          relativeImageSizeInLayout="100px"
          sx={{
            alignSelf: "center",
            width: "160px",
          }}
        />
        <Heading
          size="medium"
          sx={{
            px: "base.spacing.x3",
            textAlign: "center",
          }}
        >
          You have already claimed this reward
        </Heading>
        <Body
          size="small"
          sx={{
            color: "base.color.text.body.secondary",
            textAlign: "center",
          }}
        >
          Continue to claim more gems by completing quests.
        </Body>
        <Button
          size="large"
          variant="primary"
          onClick={handleClose}
          sx={{ mt: "auto" }}
        >
          {t("play_quests_and_earn_gems")}
        </Button>
      </RewardBaseModal>
    </>
  );
};
